import {IProjectListItem} from '../components/ProjectTypes/projectTypeApi';

export default class ProjectItem implements UIStateItem, IProjectListItem {
  public budgetAmount!: number;
  public campus!: string;
  public constructionAmount!: number;
  public costToDate!: number | string;
  public hasMap!: 1 | 0;
  public hasStatus!: 1 | 0;
  public hasType!: 1 | 0;
  public isError!: boolean;
  public isSaving!: boolean;
  public isTouched!: boolean;
  public projMapId?: number | null;
  public projectId!: number;
  public projectNo!: string;
  public projectTitle!: string;
  public projectType?: string | null;
  private _projectTypeId!: number;
  public status!: string;
  public statusDescription?: string | null;
  private _statusId!: number;
  private _cache!: [number, number]; // [statusId, typeId]
  public unifierProjectType!: string;
  private _isInit: boolean;

  constructor(item: IProjectListItem) {
    this._isInit = false;
    Object.assign(this, {isError: false, isSaving: false, isTouched: false, _cache:[]}, item);
    this._isInit = true;
  }

  save(): void {
    this._resetState();
  }

  cancel(): void {
    // @ts-ignore
    this._statusId = this._cache[0];
    // @ts-ignore
    this._projectTypeId = this._cache[1];
    this._resetState();
  }

  _resetState(): void {
    this.isSaving = false;
    this.isTouched = false;
    this.isError = false;
    this._resetCachedValues();
  }

  _resetCachedValues(): void {
    // @ts-ignore
    this._cache = [];
  }

  get projectTypeId(): number {
    return this._projectTypeId;
  }

  set projectTypeId(value: number)
  {
    if(this._isInit) this.isTouched = true;
    this._cache[1] = this._projectTypeId;
    this._projectTypeId = value;
  }

  get statusId(): number {
    return this._statusId;
  }

  set statusId(value: number) {
    if (this._isInit) this.isTouched = true;
    this._cache[0] = this._statusId;
    this._statusId = value;
  }

}

interface UIStateItem {
  isTouched: boolean;
  isSaving: boolean;
  isError: boolean;
  save(): void;
  cancel(): void;
}