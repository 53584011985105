import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './containers/App.container'
import Notifier from './containers/Notifier'
import {unregister} from './registerServiceWorker'
import errorReport from './utils/errorReport'

window.addEventListener('error',({error})=>{
  errorReport(error);
  return false;
});

const noGo = <h1>This application must be viewed in Unifier</h1>;
const isProd = process.env.NODE_ENV;
const acceptableUrls = ['unifier.sandi.net','prdecprwb06','prdecprwb07', 'prdecprwb08'];
const RenderMe = isProd === 'development' || acceptableUrls.indexOf(document.referrer.split('/')[2]) >= 0 ? <><App/><Notifier/></> : noGo;

ReactDOM.render(RenderMe, document.getElementById('root'));
unregister();
