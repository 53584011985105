import React, {PureComponent} from 'react';
import AdminPanel from './AdminPanel';
import {
  createEditable,
  createProjectType,
  createStatus,
  deleteProjectType,
  deleteStatus,
  getProjectTypes,
  getStatuses,
  IEditable,
  IEditableStatus,
  IProjectType,
  IStatus,
  updateProjectType,
  updateStatus
} from './adminApi';

const twin: any = window;
const {confirm} = twin;
const initialState: IAdminContainerState = {
  projectTypes: [], statuses: []
};

class AdminContainer extends PureComponent<IAdminContainerProps, IAdminContainerState> {
  constructor(props: IAdminContainerProps) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Promise.all([getProjectTypes(), getStatuses()])
      .then(([projectTypes, statuses]) => {
        this.setState({
          projectTypes: projectTypes.map(createEditable),
          statuses: statuses.map(createEditable)
        })
      })
  };

  getIndexById = (item: {id: number;}, items: any[]) => {
    return items.findIndex((i) => i.id === item.id)
  };

  addProjectType = () => {
    const {projectTypes} = this.state;
    const newItem: IEditableProjectType = {
      isEditing: true, projectType: '', isNew: true, id: new Date().valueOf()
    };
    this.setState({
      projectTypes: [newItem,...projectTypes]
    })
  };

  addStatus = () => {
    const {statuses} = this.state;
    const newItem: IEditableStatus = {
      isEditing: true, id: new Date().valueOf(), isNew: true, name: ''
    };
    this.setState({
      statuses: [newItem, ...statuses]
    })
  };

  saveProjectType = (projectType: IEditableProjectType) => {
    const {projectTypes} = this.state;
    const idx = this.getIndexById(projectType, projectTypes);
    // check if idx exists
    if(idx > -1) {
      // check if type is new or is being updated
      new Promise((resolve) => {
        if (projectType.isNew) {
         return createProjectType(projectType.projectType).then(resolve)
        } else {
          return  updateProjectType(projectType).then(resolve)
        }
      })
        .then((res: any) => {
          // replace our generated id with real PK from DB
          projectType.id = res.data.id;
          projectType.isEditing = false;
          projectType.isNew = false;
          this.setState({
            projectTypes: [
              ...projectTypes.slice(0, idx),
              projectType,
              ...projectTypes.slice(idx+1)
            ]
          })
        })
    }
  };

  deleteProjectType = (projectType: IProjectType) => {
    if (confirm(`Are you sure you want to delete ${projectType.projectType}?\nThis cannot be undone!`)) {
      deleteProjectType(projectType.id)
        .then(() => {
          const {projectTypes} = this.state;
          const idx = this.getIndexById(projectType, projectTypes);
          // check if idx exists
          if (idx > -1) {
            this.setState({
              projectTypes: [
                ...projectTypes.slice(0, idx),
                ...projectTypes.slice(idx + 1)
              ]
            });
          }
        });
    }
  };

  saveStatus = (status: IEditableStatus) => {
    const {statuses} = this.state;
    const idx = this.getIndexById(status, statuses);
    // check if idx exists
    if(idx > -1) {
      // check if type is new or is being updated
      new Promise((resolve) => {
        if (status.isNew) {
          return createStatus(status.name).then(resolve)
        } else {
          return  updateStatus(status).then(resolve)
        }
      })
        .then((res: any) => {
          // replace our generated id with real PK from DB
          status.id = res.data.id;
          status.isEditing = false;
          status.isNew = false;
          this.setState({
            statuses: [
              ...statuses.slice(0, idx),
              status,
              ...statuses.slice(idx+1)
            ]
          })
        })
    }
  };

  deleteStatus = (status: IStatus) => {
    if (confirm(`Are you sure you want to delete ${status.name}?\nThis cannot be undone!`)) {
      deleteStatus(status.id)
        .then(() => {
          const {statuses} = this.state;
          const idx = this.getIndexById(status, statuses);
          if (idx > -1) {
            this.setState({
              statuses: [
                ...statuses.slice(0, idx),
                ...statuses.slice(idx + 1)
              ]
            });
          }
        });
    }
  };

  selectProjectType = (projectType: IEditableProjectType) => {
    const {projectTypes} = this.state;
    const idx = this.getIndexById(projectType,projectTypes);
    if (idx > -1) {
      projectTypes[idx].isEditing = true;
      this.setState({
        projectTypes: [...projectTypes]
      })
    }
  };

  selectStatus = (status: IEditableStatus) => {
    const {statuses} = this.state;
    const idx = this.getIndexById(status,statuses);
    if (idx > -1) {
      statuses[idx].isEditing = true;
      this.setState({
        statuses: [...statuses]
      })
    }
  };

  onCancelEditProjectType = (index: number) => {
    const {projectTypes} = this.state;
    // if unsaved new item, remove from list
    if(projectTypes[index].isNew){
      projectTypes.splice(index,1);
      return this.setState({
        projectTypes: [...projectTypes]
      })
    } else {
      projectTypes[index].isEditing = false;
      this.setState({
        projectTypes: [...projectTypes]
      })
    }
  };

  cancelEditStatus = (index: number) => {
    const {statuses} = this.state;
    // if unsaved new item, remove from list
    if(statuses[index].isNew){
      statuses.splice(index,1);
      return this.setState({
        statuses: [...statuses]
      })
    } else {
      statuses[index].isEditing = false;
      this.setState({
        statuses: [...statuses]
      })
    }
  };

  render() {
    return (
     <AdminPanel
       projectTypes={this.state.projectTypes}
       statuses={this.state.statuses}
       onAddProjectType={this.addProjectType}
       onSaveProjectType={this.saveProjectType}
       onSelectProjectType={this.selectProjectType}
       onCancelEditProjectType={this.onCancelEditProjectType}
       onDeleteProjectType={this.deleteProjectType}
       onAddStatus={this.addStatus}
       onCancelEditStatus={this.cancelEditStatus}
       onSaveStatus={this.saveStatus}
       onSelectStatus={this.selectStatus}
       onDeleteStatus={this.deleteStatus}
     />
    );
  }
}

export default AdminContainer;

interface IAdminContainerProps {
  title: string;
}

interface IAdminContainerState {
  projectTypes: IEditableProjectType[];
  statuses: IEditableStatus[];
}

export interface IEditableProjectType extends IProjectType, IEditable {}