import Ajax, {AxiosResponse, Canceler} from 'axios';

export default Ajax.create({
  baseURL: 'https://pcm.sandi.net/apiserver/api.rsc/',
  headers: {'x-cdata-authtoken': '9b2P7g8r0Q1a1j8Y2g1u'}
});

export const getResCount = <T>({data}: ICDataRes<T>): ICountRes<T> => {
  const d = data.value;
  const count = data['@odata.count'];
  return {data: d, count};
};

export const getRes = <T>({data}: ICDataRes<T>): T => {
  return data.value
};

interface ICDataRes<T> extends AxiosResponse<{'@odata.count': number; value: T}> {
}

export interface ICDataParams {
  $filter?: string;
  $orderby?: string;
  $select?: string;
  $top?: number;
  $skip?: number;
  $count?: boolean;
}

export interface ICountRes<T> {
  count: number;
  data: T;
}

export interface IPager {
  top: number;
  skip: number;
}

export const getCancelConfig = () => {
  const {token, cancel} = Ajax.CancelToken.source();
  return {
    cancelToken: token,
    cancel
  }
};

export interface ICancellable<T> {
  request: T;
  cancel: Canceler;
}