import React, {PureComponent} from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';

const TextElem = 'comment';
const selectText = function () {
  const elem: any = document.getElementById(TextElem);
  if (elem) {
    elem.select();
  }
};

class CommentDialog extends PureComponent<ICommentDialogProps, ICommentDialogState> {
  constructor(props: ICommentDialogProps) {
    super(props);
    this.state = {
      comment: props.initialValue,
      saving: false,
      errMessage: ''
    };
  }

  componentWillReceiveProps(nextProps: Readonly<ICommentDialogProps>, nextContext: any) {
    this.setState({comment: this.props.initialValue || ''});
  }

  componentWillUnmount() {
    this.setState({comment: ''});
  }

  handleChange = (e: any) => {
    this.setState({
      comment: e.target.value
    });
  };

  save = () => {
    this.setState({saving: true}, () => {
      this.props.save(this.state.comment)
        .then(() => {
          this.setState({saving: false, errMessage: ''});
        })
        // @ts-ignore
        .catch((err) => {
          console.log(err);
          this.setState({saving: false, errMessage: err.message});
        });
    });
  };

  close = () => {
    this.setState({saving: false}, this.props.cancel);
  };

  render() {
    const {classes} = this.props;
    return (
      <Dialog open={this.props.isOpen} classes={classes} onClose={this.close}>
        <DialogTitle>Comment</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.save();
            }}
          >
            <TextField
              autoFocus={true}
              onFocus={selectText}
              margin="dense"
              id={TextElem}
              label="Comment"
              type="text"
              value={this.state.comment}
              onChange={this.handleChange}
              multiline={true}
              rows={4}
              fullWidth={true}
            />
          </form>
        </DialogContent>
        <DialogActions>
          {this.state.errMessage && <Typography color="error">{this.state.errMessage}</Typography>}
          <Button onClick={this.close} color="primary">
            Cancel
          </Button>
          <Button
            disabled={this.state.saving}
            onClick={this.save}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = createStyles({
  paper: {
    width: '80%'
  }
});
export default withStyles(styles)(CommentDialog);

interface ICommentDialogProps extends WithStyles<typeof styles> {
  isOpen: boolean;
  initialValue: string;

  cancel(): void;

  save(comment: string): PromiseLike<any>;
}

interface ICommentDialogState {
  comment: string;
  saving: boolean;
  errMessage: string;
}