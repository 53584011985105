import React, {PureComponent} from 'react';
import Action from '@material-ui/icons/PlaylistAdd';
import {Button, createStyles, Menu, MenuItem, Theme, withStyles, WithStyles} from '@material-ui/core';
import {blue} from '@material-ui/core/colors';
import {TStatus} from '../../utils/constants';

class ActionsMenu extends PureComponent<IActionMenuProps, IActionMenuState> {
  constructor(props: IActionMenuProps) {
    super(props);
    this.state = {
      anchorEl: undefined,
      open: false,
    };
  }

  handleClick = (e: any) => {
    this.setState({open: true, anchorEl: e.currentTarget});
  };

  handleRequestClose = (cb?: () => void) => {
    this.setState({open: false}, cb);
  };

  render() {
    const {setActive, setComplete, setExclude, updateComment, poId, poReviewId, comments, status, classes} = this.props;
    return (
      <div>
        <Button
          aria-owns={this.state.open ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <Action className={`${classes.icon} ${classes.iconBlue}`}/>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={() => this.handleRequestClose()}
        >
          {
            // Set Active Button
            // ====================================
            setActive && (
              <MenuItem
                onClick={() => {
                  this.handleRequestClose(() => {
                    setActive(poId, poReviewId, comments, status);
                  });
                }}
              >
                Set as Active
              </MenuItem>)
            // End Active Button
          }
          {
            // Set Complete Button
            // ====================================
            setComplete && (
              <MenuItem
                onClick={() => {
                  this.handleRequestClose(() => {
                    setComplete(poId, poReviewId, comments, status);
                  });
                }}
              >
                Set as Complete
              </MenuItem>)
            // End Complete Button
          }
          {
            // Set Exclude Button
            // ====================================
            setExclude && (
              <MenuItem
                onClick={() => {
                  this.handleRequestClose(() => {
                    setExclude(poId, poReviewId, comments, status);
                  });
                }}
              >
                Set as Excluded
              </MenuItem>)
            // End Exclude Button
          }
          {
            updateComment && (
              <MenuItem
                onClick={() => {
                  updateComment(poId, poReviewId, comments, status);
                }}
              >
                Update Comment
              </MenuItem>)
          }
        </Menu>
      </div>
    );
  }
}

const styles = (theme: Theme) => createStyles({
  icon: {
    margin: theme.spacing(1)
  },
  iconBlue: {
    fill: blue[500],
    '&:hover': {
      fill: blue[200]
    }
  }
});

export default withStyles(styles)(ActionsMenu);

interface IActionMenuProps extends WithStyles<typeof styles> {
  poId: number;
  poReviewId: number;
  comments: string;
  status: TStatus;

  setActive?(poId: number, poReviewId: number, comments: string, status: TStatus): void;

  setComplete?(poId: number, poReviewId: number, comments: string, status: TStatus): void;

  setExclude?(poId: number, poReviewId: number, comments: string, status: TStatus): void;

  updateComment?(poId: number, poReviewId: number, comments: string, status: TStatus): void;
}

interface IActionMenuState {
  anchorEl?: HTMLElement;
  open: boolean;
}