import ajax, {getRes} from '../../utils/ajax';

const PROJECT_TYPE = 'unifierProjectTypes';
const PROJECT_STATUS = 'unifierProjectStatus';

export const getProjectTypes = (): Promise<IProjectType[]> => {
  // @ts-ignore doesn't think return type matches function signature
  return ajax.get(PROJECT_TYPE)
    .then(getRes)
};

export interface IProjectType {
  id: number;
  projectType: string;
  description?: string;
  createDate?: string;
}

export const updateProjectType = ({id, projectType}: IProjectType): Promise<any> => {
  return ajax.put(`${PROJECT_TYPE}(${id})`, {projectType})
};

export const createProjectType = (projectType: string): Promise<any> => {
  return ajax.post(PROJECT_TYPE, {projectType})
};

export const deleteProjectType = (id: number) => {
  return ajax.delete(`${PROJECT_TYPE}(${id})`)
};

export interface IEditable {
  isEditing: boolean;
  isNew: boolean;
}

export const getStatuses = (): Promise<IStatus[]> => {
  // @ts-ignore unknown return type
  return ajax.get(PROJECT_STATUS)
    .then(getRes)
};

export const updateStatus = ({id, name}: IStatus) => {
  return ajax.put(`${PROJECT_STATUS}(${id})`, {name})
};

export const createStatus = (name: string) => {
  return ajax.post(PROJECT_STATUS, {name})
};

export const deleteStatus = (id: number) => {
  return ajax.delete(`${PROJECT_STATUS}(${id})`)
};

export interface IEditableStatus extends IStatus, IEditable {}

export interface IStatus extends ICreateStatus {
  id: number;
  description?: string;
  createDate?: string;
}

export interface ICreateStatus {
  name: string;
}

export function createEditable<T>(t: T){
  return {
    isNew: false, isEditing: false, ...t
  }
}