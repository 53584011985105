
import ajax from './ajax'
import {APP} from './constants'

export default (e)=>{
  const defaults ={
    "stack": JSON.stringify(e),
    "message": "No error message",
    "link": "",
    "code": ""
  };
  return ajax.post('applicationError',{...defaults,applicationName: APP,...e})
};