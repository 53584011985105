import React, {PureComponent} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {Button, createStyles, IconButton, Snackbar, Theme, withStyles, WithStyles} from '@material-ui/core';

const twin: any = window;

class Notifier extends PureComponent<INotifierProps, INotifierState> {
  constructor(props: INotifierProps) {
    super(props);
    this.state = {
      open: false,
      message: undefined,
      autoClose: true
    };
  }

  componentDidMount() {
    twin.setSnackbar = this.setSnackbar;
  }

  componentWillUnmount() {
    twin.setSnackbar = undefined;
  }

  setSnackbar = (message: string, autoClose = true) => {
    if(!message) return;
    this.setState({open: true, message, autoClose});
  };

  close = (e?: any) => {
    this.setState({open: false, message: ''});
  };

  handleRequestClose = (e: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.close()
  };

  render() {
    const {
      props: {classes},
      state: {message, autoClose},
    } = this;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.state.open}
        autoHideDuration={autoClose ? 6000 : 1000 * 60 * 60} // 1 hr
        onClose={this.handleRequestClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={message}
        action={[
          <Button key="undo" color="secondary" size="medium" onClick={this.close}>
            Close
          </Button>,
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.close}
          >
            <CloseIcon/>
          </IconButton>,
        ]}
      />
    );
  }
}

const styles = (theme: Theme) => createStyles({
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
});

export default withStyles(styles)(Notifier);

twin.onerror = (errorMsg: string, url: string, lineNumber: number) => {
  if (twin.setSnackbar) {
    twin.setSnackbar('Error occured: ' + errorMsg);
  }
  console.log('Unhandled Exception! at %s on %s', url, lineNumber);
  return false;
};

interface INotifierProps extends WithStyles<typeof styles> {
}

interface INotifierState {
  open: boolean;
  message?: string;
  autoClose: boolean;
}