import React from 'react';
import {ColDef, ICellRendererParams} from 'ag-grid-community';
import accounting from 'accounting';
import {IProjectListItem} from './projectTypeApi';
import {IProjectType, IStatus} from '../AdminPanel/adminApi';
import {Button, ButtonGroup, MenuItem, Select} from '@material-ui/core';
import ProjectItem from '../../lib/ProjectItem';

accounting.settings.currency.precision = 0;

export const getColumnDefinitions: (params: GetColDefParams) => ColDef[] = (params) => [
  {headerName: 'Project Title', field: 'projectTitle', width: 450, suppressSizeToFit: true},
  {headerName: '', width: 100, cellRenderer: 'actionButtonsComponent', cellRendererParams: params, resizable: true},
  {headerName: 'Project No.', field: 'projectNo', width: 100, resizable: true},
  {
    headerName: 'Assigned Project Type', width: 375,
    cellRenderer: 'projectTypeSelectComponent', cellRendererParams: params, resizable: true
  },
  {headerName: 'Unifier Project Type', field: 'unifierProjectType', width: 200, resizable: true},
  {
    headerName: 'Status', width: 150,
    cellRenderer: 'statusSelectComponent', cellRendererParams: params, resizable: true
  },
  {
    headerName: 'Budget', field: 'budgetAmount', width: 100, type: 'numericColumn', resizable: true,
    valueGetter(row) {
      return accounting.formatMoney(row.data.budgetAmount);
    }
  },
  {
    headerName: 'Cost to Date', field: 'costToDate', width: 100, type: 'numericColumn', resizable: true,
    valueGetter(row) {
      return accounting.formatMoney(row.data.costToDate);
    }
  },
  {
    headerName: 'Const. Value', field: 'constructionAmount', width: 100, type: 'numericColumn', resizable: true,
    valueGetter(row) {
      return accounting.formatMoney(row.data.constructionAmount);
    }
  }
];

export interface GetColDefParams {
  projectTypes: IProjectType[];
  statuses: IStatus[];
  onSelectProjectType(projectTypeId: number, item: IProjectListItem, cb?: () => void): void;
  onSelectStatusType(statusId: number, item: IProjectListItem, cb?: () => void): void;
  onSave(item: IProjectListItem, cb?: () => void): void;
  onCancel(item: IProjectListItem, cb?: () => void): void;
}

export const ActionButtonsComponent: React.FC<ICellRendererParams & GetColDefParams> = (props) => {
  const data: ProjectItem = props.data;
  const redraw = () => props.api.redrawRows();
  return data.isTouched ? (
    <ButtonGroup size="small" aria-label="Cancel or Save Edits">
      <Button onClick={() => props.onSave(props.data, redraw)}>Save</Button>
      <Button onClick={() => props.onCancel(props.data, redraw)}>Cancel</Button>
    </ButtonGroup>
  ) : <span/>;
};

export const ProjectTypeSelectComponent: React.FC<ICellRendererParams & GetColDefParams> = (props) => {
  const data: ProjectItem = props.data;
  return (
    <Select
      value={data.projectTypeId || ''}
      onChange={(e: any) => {
        if (!e.target.value) return;
        props.onSelectProjectType(e.target.value, data, () => props.api.redrawRows());
      }}
    >
      {props.projectTypes.map((type) => (
        <MenuItem value={type.id} key={type.id}>{type.projectType}</MenuItem>
      ))}
    </Select>
  );
};

export const StatusSelectComponent: React.FC<ICellRendererParams & GetColDefParams> = (props) => {

    return (
      <Select
        value={props.data.statusId || ''}
        onChange={(e: any) => {
          if (!e.target.value) return;
          props.onSelectStatusType(e.target.value, props.data, ()=> props.api.redrawRows());
        }}
      >
        {props.statuses.map((type) => (
          <MenuItem value={type.id} key={type.id} selected={type.id === props.data.statusId}>{type.name}</MenuItem>
        ))}
      </Select>
    );
};