import React from 'react';
import {
  Checkbox,
  createStyles,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  WithStyles, withStyles
} from '@material-ui/core';

const SelectInput = (props: ISelectInputProps) => {
  const {
    classes, name, options, multiple, onChange, selections, label
  } = props;
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        multiple={multiple}
        value={selections}
        onChange={(e: any) => onChange(e.target.value)}
        input={<Input id={name} className={classes.input}/>}
        renderValue={selected => {
          // @ts-ignore - selected might be undefined
          return selected.join(', ');
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.name} value={option.value}>
              <Checkbox checked={selections.indexOf(option.name) > -1}/>
              <ListItemText primary={option.name}/>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  );
};

const styles = createStyles({
  formControl: {
    minWidth: '10rem',
    marginLeft: '1rem',
    marginRight: '1rem'
  },
  input: {
    maxWidth: '15rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    textOverflow: 'ellipsis'
  }
});

export default withStyles(styles)(SelectInput);

interface ISelectInputProps extends WithStyles<typeof styles> {
  multiple: boolean;
  name: string;
  label: string;
  options: IOption[];
  selections: string[];
  onChange(selections: string[]): void;
}

interface IOption {
  name: string;
  value: any;
}