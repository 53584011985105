import React from 'react';
import {
  createStyles,
  IconButton,
  List,
  ListItem,
  Paper,
  Typography,
  WithStyles,
  withStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import {IEditableStatus, IProjectType, IStatus} from './adminApi';
import {IEditableProjectType} from './Admin.container';
import EditProjectTypeListItem from './EditProjectTypeListItem';
import EditStatusListItem from './EditStatusListItem';

const AdminPanel = (props: IAdminPanelProps) => {
  const {classes, projectTypes, statuses} = props;
  return (
    <Paper className={`${classes.paperSpace} ${classes.root}`}>
      <Typography variant="h4">Administration</Typography>
      <Typography>Manage project types, project statuses</Typography>
      <div className={classes.container}>
        <Paper className={`${classes.paperSpace} ${classes.containerItem}`}>
          <Typography variant="h4">Project Types</Typography>
          <IconButton onClick={props.onAddProjectType}><AddIcon/></IconButton>
          <br/>
          <List className={classes.halfHeight}>
            {projectTypes.map((item, index) => {
              if (item.isEditing) {
                return (
                  <EditProjectTypeListItem
                    key={`${item.id}-${index}`}
                    item={item}
                    onSave={props.onSaveProjectType}
                    onCancelEdit={() => props.onCancelEditProjectType(index)}
                    onDelete={() => props.onDeleteProjectType(item)}
                  />
                )
              } else {
                return (
                  <ListItemDisplay
                    key={`${index}-${item.id}`}
                    title={item.projectType}
                    onSelect={() => props.onSelectProjectType(item)}
                  />
                );
              }
            })
            }
          </List>
        </Paper>
        <Paper className={`${classes.paperSpace} ${classes.containerItem}`}>
          <Typography variant="h4">Status Types</Typography>
          <IconButton onClick={props.onAddStatus}><AddIcon/></IconButton>
          <br/>
          <List className={classes.halfHeight}>
            { statuses ? (
              statuses.map((stat, index) => {
                if (stat.isEditing) {
                  return (
                    <EditStatusListItem
                      key={`${stat.id}-${index}`}
                      item={stat}
                      onSave={props.onSaveStatus}
                      onCancelEdit={() => props.onCancelEditStatus(index)}
                      onDelete={() => props.onDeleteStatus(stat)}
                    />
                  )
                } else {
                  return (
                    <ListItemDisplay
                      key={`${index}-${stat.id}`}
                      title={stat.name}
                      onSelect={() => props.onSelectStatus(stat)}
                    />
                  );
                }
              })
            ) : (
              <ListItemDisplay
                title="Add Status by selecting the button above."
                onSelect={() => {/* */}}
              />
            )
            }
          </List>
        </Paper>
      </div>
    </Paper>
  );
};

const styles = createStyles({
  container: {
    display: 'flex',
  },
  containerItem: {
    flexGrow: 1
  },
  root: {
    marginRight: 'auto', marginLeft: 'auto',
    width: '75%'
  },
  paperSpace: {
    padding: '1rem',
    margin: '1rem'
  },
  halfHeight: {
    height: '40vw',
    overflow: 'auto'
  }
});

export default withStyles(styles)(AdminPanel);

interface IAdminPanelProps extends WithStyles<typeof styles> {
  projectTypes: IEditableProjectType[];
  statuses: IEditableStatus[];
  onAddProjectType(): void;
  onSelectProjectType(projectType: IProjectType): void;
  onSaveProjectType(projectType: IProjectType): void;
  onCancelEditProjectType(index: number): void;
  onDeleteProjectType(projectType: IProjectType): void;
  onAddStatus(): void;
  onSelectStatus(status: IStatus): void;
  onSaveStatus(status: IStatus): void;
  onCancelEditStatus(index: number): void;
  onDeleteStatus(status: IStatus): void;
}

const ListItemDisplay = (props: IListItemDisplayProps) => {
  return (
    <ListItem onClick={props.onSelect}>{props.title}</ListItem>
  );
};

interface IListItemDisplayProps {
  title: string;
  onSelect(): void;
}
