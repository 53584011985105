import React, {Component} from 'react';
import {IconButton, InputAdornment, ListItem, TextField} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import {IEditableProjectType} from './Admin.container';


class EditProjectTypeListItem extends Component<IEditListItemProps, IEditListItemState> {
  constructor(props: IEditListItemProps) {
    super(props);
    this.state = {
      error: false,
      ...props.item
    };
  }

  onChange = (e: any) => {
    const newState: any = {
      [e.target.name]: e.target.value
    };
    newState.error = !e.target.value.length;
    // @ts-ignore any does not conform to IEditableProjectTypeProps
    this.setState(newState)
  };

  onSave = () => {
    if(!this.state.projectType) {
      return this.setState({error: true});
    }
    this.props.onSave(this.state);
  };

  render() {
    return (
      <ListItem>
        <TextField
          name="projectType"
          value={this.state.projectType}
          onChange={this.onChange}
          required={true}
          error={this.state.error}
          placeholder="Name"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton title="Save" onClick={this.onSave}><SaveIcon/></IconButton>
                <IconButton title="Cancel" onClick={this.props.onCancelEdit}><CancelIcon/></IconButton>
                <IconButton title="Delete" onClick={this.props.onDelete}><DeleteIcon/></IconButton>
              </InputAdornment>)
          }}
        />
      </ListItem>
    );
  }
}

export default EditProjectTypeListItem;

interface IEditListItemProps {
  item: IEditableProjectType;
  onSave(item: IEditableProjectType): void;
  onCancelEdit(): void;
  onDelete(): void;
}

interface IEditListItemState extends IEditableProjectType {
  error: boolean;
}
