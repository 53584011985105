import React, {ErrorInfo, PureComponent} from 'react';
import {Omit, Tab, Tabs} from '@material-ui/core';
import errorReport from '../utils/errorReport';
import CommentDialog from '../components/ContractPicker/CommentDialog';
import Table from '../components/ContractPicker/ContractPickerTable';
import {
  getActiveContracts,
  getCompletedContracts,
  getContractsToReview,
  getExcludedContracts,
  IContract,
  IEditContract,
  saveNewContract,
  updateContract
} from '../utils/actions';
import {ACTIVE, COMPLETE, EXCLUDE, NEW, TStatus} from '../utils/constants';
import AdminContainer from '../components/AdminPanel/Admin.container';
import ProjectTypes from '../components/ProjectTypes/ProjectTypes.container';
import {IPager} from '../utils/ajax';

const twin: any = window;
const initialState: IAppState = {
  commentDialogOpen: false,
  comments: '',
  poId: 0,
  status: 'Active', // one of active, complete, exclude constants. For Dialog Box Styling, and determining which table to refresh after save
  fromStatus: 'Active', // one of active, complete, exclude constants. For refreshing table after save
  poReviewId: 0, // if filled in will UPDATE instead of create
  excludedContracts: [],
  excludedContractsSpin: false,
  excludedContractsCount: 0,
  activeContracts: [],
  activeContractsSpin: false,
  activeContractsCount: 0,
  completedContracts: [],
  completedContractsSpin: false,
  completedContractsCount: 0,
  contractsToReview: [],
  contractsToReviewSpin: false,
  contractsToReviewCount: 0,
  tabIdx: 0
};

class App extends PureComponent<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = initialState;
  }


  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    errorReport(error);
    if(error.message) {
      twin.setSnackbar(error.message);
    }
  }

// ====================================
// ========= DIALOG CONTROLS ==========
// ====================================
  openDialog = async () => {
    
    this.setState({
      commentDialogOpen: true
    });
  };
  closeDialog = async () => {
    
    this.setState({
      commentDialogOpen: false
    });
  };
// END DIALOG CONTROLS
// ====================================

// ====================================
// ==== USER INTERACTION CONTROLS =====
// ====================================

  onTabSelect = (e: any, tabIdx: number) => {
    return this.setState({tabIdx});
  };

  addActiveButtonHandler = (poId: number, poReviewId: number, comments: string) => {
    this._setRecordStatus({poId, poReviewId, status: ACTIVE, comments});
  };
  addCompleteButtonHandler = (poId: number, poReviewId: number, comments: string) => {
    this._setRecordStatus({poId, poReviewId, status: COMPLETE, comments});
  };
  addExcludeButtonHandler = (poId: number, poReviewId: number, comments: string) => {
    this._setRecordStatus({poId, poReviewId, status: EXCLUDE, comments});
  };

  updateCommentButtonHandler = (poId: number, poReviewId: number, comments: string, status: TStatus) => {
    this._setRecordStatus({poId, poReviewId, status, comments});
  };

  saveCommentDialogButtonHandler = (comments: string) => {
    const {poId, poReviewId, status, fromStatus} = this.state;
    return Promise.resolve()
      .then(() => {
        if (poReviewId) {
          return updateContract(poReviewId, {comments, status});
        }
        return saveNewContract({poId, comments, status});
      })
      .then(this.closeDialog)
      .then(() => {
        return this.refreshTables({status, fromStatus});
      });
  };

  /**
   * Set Record Status
   * @param vals {object}
   * @param vals.poId {number}
   * @param vals.poReviewId {number}
   * @param vals.status {string}
   * @param vals.comments {string}
   * @private
   */
  _setRecordStatus = (vals: Omit<IEditContract, 'reviewDate'>) => {
    // @ts-ignore -- doesn't like partial update
    this.setState(vals, this.openDialog);
  };
// END USER INTERACTION CONTROLS
// ====================================

// ====================================
// ======== GET DATA CONTROLS =========
// ====================================
  getExcludedContracts = () => {
    return async (pager: IPager) => {
      
      this.setState({excludedContractsSpin: true});
      const {data, count} = await getExcludedContracts(pager);
      return this.setState({
        excludedContracts: data,
        excludedContractsCount: count,
        excludedContractsSpin: false
      });
    };
  };

  getCompletedContracts = () => {
    return async (pager: IPager) => {
      this.setState({completedContractsSpin: true});
      const {data, count} = await getCompletedContracts(pager);
      return this.setState({
        completedContracts: data,
        completedContractsSpin: false,
        completedContractsCount: count
      });
    };
  };

  getActiveContracts = () => {
    return async (pager: IPager) => {
      this.setState({activeContractsSpin: true});
      const {count, data} = await getActiveContracts(pager);
      return this.setState({
        activeContracts: data,
        activeContractsSpin: false,
        activeContractsCount: count
      });
    };
  };

  getContractsToReview = () => {
    return async (pager: IPager) => {
      this.setState({contractsToReviewSpin: true});
      const {data, count} = await getContractsToReview(pager);
      return this.setState({
        contractsToReview: data,
        contractsToReviewSpin: false,
        contractsToReviewCount: count
      });
    };
  };

  clearTable = (status: TStatus) => {
    const statusMap: { [prop: string]: () => Promise<any> } = {
      [ACTIVE]: () => {
        return new Promise((resolve) => {
          const newState = {activeContracts: [], activeContractsCount: 0};
          this.setState(newState, resolve);
        });
      },
      [COMPLETE]: () => {
        return new Promise((resolve) => {
          const newState = {completedContracts: [], completedContractsCount: 0};
          this.setState(newState, resolve);
        });
      },
      [EXCLUDE]: () => {
        return new Promise((resolve) => {
          const newState = {excludedContracts: [], excludedContractsCount: 0};
          this.setState(newState, resolve);
        });
      },
      [NEW]: () => {
        return new Promise((resolve) => {
          const newState = {contractsToReview: [], contractsToReviewCount: 0};
          this.setState(newState, resolve);
        });
      }
    };
    return statusMap[status]();
  };

  refreshTables = ({status, fromStatus}: { status: TStatus; fromStatus: TStatus }) => {
    // Evaluate which tables to refresh
    // if either status or fromStatus equals
    // the constant
    // ====================================
    switch (true) {
      case (status === ACTIVE || fromStatus === ACTIVE):
        this.clearTable(ACTIVE)
          .then(this.getActiveContracts);
        break;
      case (status === COMPLETE || fromStatus === COMPLETE):
        this.clearTable(COMPLETE)
          .then(this.getCompletedContracts);
        break;
      case (status === EXCLUDE || fromStatus === COMPLETE):
        this.clearTable(EXCLUDE)
          .then(() => this.clearTable(COMPLETE))
          .then(this.getExcludedContracts);
        break;
      default:
        if (fromStatus === NEW) {
          this.clearTable(NEW)
            .then(this.getContractsToReview);
        }
    }
    this.clearTable(NEW).then(this.getContractsToReview);
  };
// END DATA CONTROLS
// ====================================

  render() {
    const {tabIdx} = this.state,
      Tables = [(
        <Table
          key="review"
          title="Contracts to Review"
          getData={this.getContractsToReview}
          data={this.state.contractsToReview}
          spin={this.state.contractsToReviewSpin}
          setActive={this.addActiveButtonHandler}
          setComplete={this.addCompleteButtonHandler}
          setExclude={this.addExcludeButtonHandler}
          updateComment={this.updateCommentButtonHandler}
          hideComments={true}
          totalCount={this.state.contractsToReviewCount}
        />),
        (
          <Table
            key="active"
            title="Active Contracts"
            getData={this.getActiveContracts}
            data={this.state.activeContracts}
            spin={this.state.activeContractsSpin}
            setComplete={this.addCompleteButtonHandler}
            setExclude={this.addExcludeButtonHandler}
            updateComment={this.updateCommentButtonHandler}
            totalCount={this.state.activeContractsCount}
          />),
        (
          <Table
            key="completed"
            title="Completed Contracts"
            getData={this.getCompletedContracts}
            data={this.state.completedContracts}
            spin={this.state.completedContractsSpin}
            setActive={this.addActiveButtonHandler}
            setExclude={this.addExcludeButtonHandler}
            updateComment={this.updateCommentButtonHandler}
            totalCount={this.state.completedContractsCount}
          />),
        (
          <Table
            key="excluded"
            title="Excluded Contracts"
            getData={this.getExcludedContracts}
            data={this.state.excludedContracts}
            spin={this.state.excludedContractsSpin}
            setComplete={this.addCompleteButtonHandler}
            setActive={this.addActiveButtonHandler}
            updateComment={this.updateCommentButtonHandler}
            totalCount={this.state.excludedContractsCount}
          />), (
          <ProjectTypes title="Project Types" key="project-types-container"/>
        ), (
          <AdminContainer title="Admin Panel" key="admin-container"/>
        )];
    return (
      <>
        <CommentDialog
          isOpen={this.state.commentDialogOpen}
          cancel={this.closeDialog}
          save={this.saveCommentDialogButtonHandler}
          initialValue={this.state.comments}
        />
        <Tabs
          value={tabIdx}
          onChange={this.onTabSelect}
          indicatorColor="primary"
          textColor="primary"
          centered={true}
          scrollButtons="auto"
        >
          {Tables.map((tbl, i) => {
            return <Tab label={tbl.props.title} key={`table-${i}`}/>;
          })}
        </Tabs>
        {Tables[tabIdx]}
      </>
    );
  }
}


export default App;

interface IAppState {
  commentDialogOpen: boolean;
  comments: string;
  poId: number;
  status: TStatus;
  fromStatus: TStatus;
  poReviewId: number;
  excludedContracts: IContract[]
  excludedContractsSpin: boolean;
  excludedContractsCount: number;
  activeContracts: IContract[]
  activeContractsSpin: boolean;
  activeContractsCount: number;
  completedContracts: IContract[]
  completedContractsSpin: boolean;
  completedContractsCount: number;
  contractsToReview: IContract[]
  contractsToReviewSpin: boolean;
  contractsToReviewCount: number;
  tabIdx: number
}

interface IAppProps {
}