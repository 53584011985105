import React, {Component} from 'react';
import {IconButton, InputAdornment, ListItem, TextField} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import {IEditableStatus} from './adminApi';
import DeleteIcon from '@material-ui/icons/Delete';

class EditStatusListItem extends Component<IEditStatusProps, IEditStatusState> {
  constructor(props: IEditStatusProps) {
    super(props);
    this.state = {
      error: false,
      ...props.item
    };
  }

  onChange = (e: any) => {
    const newState: any = {
      [e.target.name]: e.target.value
    };
    newState.error = !e.target.value.length;
    // @ts-ignore any does not conform to IEditableStatusProps
    this.setState(newState)
  };

  onSave = () => {
    if(!this.state.name) {
      return this.setState({error: true});
    }
    this.props.onSave(this.state);
  };

  render() {
    return (
      <ListItem>
        <TextField
          name="name"
          value={this.state.name}
          onChange={this.onChange}
          required={true}
          error={this.state.error}
          placeholder="Name"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton title="Save" onClick={this.onSave}><SaveIcon/></IconButton>
                <IconButton title="Cancel" onClick={this.props.onCancelEdit}><CancelIcon/></IconButton>
                <IconButton title="Delete" onClick={this.props.onDelete}><DeleteIcon/></IconButton>
              </InputAdornment>)
          }}
        />
      </ListItem>
    );
  }
}

export default EditStatusListItem;

interface IEditStatusProps {
  item: IEditableStatus;
  onSave(item: IEditableStatus): void;
  onCancelEdit(): void;
  onDelete(): void;
}

interface IEditStatusState extends IEditableStatus {
  error: boolean;
}
