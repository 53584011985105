import ajax, {getResCount, ICDataParams, ICountRes, IPager} from './ajax';
import errorReport from './errorReport';
import {ACTIVE, COMPLETE, EXCLUDE, NEW, TStatus} from './constants';

const twin: any = window;

export const getContractsToReview = (pager: IPager) => {
  return getContracts(NEW, pager);
};
export const getActiveContracts = (pager: IPager) => {
  return getContracts(ACTIVE, pager);
};

export const getCompletedContracts = (pager: IPager) => {
  return getContracts(COMPLETE, pager);
};

export const getExcludedContracts = (pager: IPager) => {
  return getContracts(EXCLUDE, pager);
};

let saving: boolean = false;
export const saveNewContract = (data: IEditContract): Promise<any> => {
  if (saving) {
    return Promise.resolve();
  } else {
    saving = true;
    data.reviewDate = createReviewDate();
    data.comments = data.comments || '';
    return ajax.post('cmIcocRptPoFilter', data)
      .then((...args) => {
        saving = false;
        return args;
      })
      .catch((err) => {
        saving = false;
        return Promise.reject(err);
      });
  }
};
let updating: boolean = false;
export const updateContract = (id: number, data: IEditContract): Promise<any> => {
  if (updating) {
    return Promise.resolve();
  } else {
    updating = true;
    data.reviewDate = createReviewDate();
    return ajax.put(`cmIcocRptPoFilter(${id})`, data)
      .then((...args) => {
        updating = false;
        return args;
      })
      .catch((err) => {
        updating = false;
        return Promise.reject(err);
      });
  }
};

const getContracts = (type: TStatus, pager: IPager): Promise<ICountRes<IContract[]>> => {
  const params: ICDataParams = {
    $filter: `poReviewStatus eq "${type}"`,
    $orderby: 'campus asc, contractNumber desc',
    $skip: pager.skip,
    $top: pager.top,
    $count: true
  };
  return ajax.get('cmICOCContracts', {params})
    .then(getResCount)
    .catch((err) => {
      errorReport(err);
      if (twin.setSnackbar) {
        return twin.setSnackbar(err.message);
      }
      console.log('Snackbar not found!\n%s', err);
    });
};

const createReviewDate = () => {
  const d = new Date();
  const dd = twoChars(d.getDate());
  const mo = twoChars(d.getMonth()+1);
  const hh = twoChars(d.getHours());
  const ss = twoChars(d.getMilliseconds());
  // return date format YYYY-MM-DD HH:MM:SS
  return `${d.getFullYear()}-${mo}-${dd} ${hh}:${d.getMinutes()}:${ss}`;
};

function twoChars (no: number): string {
  return (`0${no}`).substr(0,2)
}

export interface IContract {
  poId: number;
  constructionAmount: number;
  projectNumber: string;
  poReviewStatus: TStatus;
  poReviewDate: string;
  campus: string;
  poReviewId: number;
  projectId: number;
  projectTitle: string;
  title: string;
  contractNumber: string;
  vendor: string;
  poReviewComments?: string;
}

export interface IEditContract extends Partial<ICreateReview> {
}

export interface ICreateReview {
  poReviewId: number;
  poId: number;
  reviewDate: string;
  comments: string;
  status: TStatus;
}