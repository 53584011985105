import React, {Component, ErrorInfo} from 'react';
import {
  createProjectMap,
  getProjectList,
  updateProjectMap
} from './projectTypeApi';
import {ICDataParams} from '../../utils/ajax';
import ProjectTable from './ProjectTable';
import {getProjectTypes, getStatuses, IProjectType, IStatus} from '../AdminPanel/adminApi';
import {Canceler} from 'axios';
import ProjectItem from '../../lib/ProjectItem';
import Spinner from '../Spinner';

const twin: any = window;
const initialState: IProjectTypesState = {
  projectList: [],
  projectTypes: [],
  projectType: [],
  statuses: [],
  status: [],
  assignOnly: true,
  spin: true,
  search: ''
};

class ProjectTypes extends Component<IProjectTypesProps, IProjectTypesState> {
  private cancelSearch?: Canceler;

  constructor(props: IProjectTypesProps) {
    super(props);
    this.state = initialState;
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    twin.setSnackbar(error.message);
  }

  componentDidMount() {
    return Promise.all([this.getProjectTypes(), this.getStatuses()])
      .then(this.getData)
      .catch((err) => {
        this.setState({spin: false});
        twin.setSnackbar(err.message);
      });
  }

  componentWillUnmount(): void {
    if (this.cancelSearch) {
      this.cancelSearch();
    }
  }

  getProjectTypes = () => {
    return getProjectTypes()
      .then((projectTypes) => {
        this.setState({projectTypes});
      });
  };

  getStatuses = () => {
    return getStatuses()
      .then((statuses) => {
        this.setState({statuses: [...statuses, {name: 'No Status', id: -1}]});
      });
  };

  createFilters = (): string => {
    const {search, projectType, status, assignOnly} = this.state;
    let filters: string[] = [];
    // create filters in this format:
    // (projectType eq 'value' or projectType eq 'value') and (status eq 'value' or status eq 'value)
    if (assignOnly) {
      filters.push('hasMap eq 0 or hasType eq 0');
    }
    if (search) {
      filters.push(`contains(projectTitle, "${search}") or contains(projectNo,"${search}")`);
    }
    if (projectType.length) {
      filters.push(`${projectType.map((value) => `projectType eq '${value}'`).join(' or ')}`);
    }
    if (status.length) {
      filters.push(`${status.map((value) => `status eq '${value}'`).join(' or ')}`);
    }
    return `(${filters.join(') and (')})`;
  };

  getData = () => {
    if (this.cancelSearch) {
      this.cancelSearch();
    }
    const params: ICDataParams = {};
    const filters = this.createFilters();
    if (filters !== '()') {
      params['$filter'] = filters;
    }
    params['$orderby'] = 'campus asc, projectTitle asc';

    this.setState({spin: true});

    const {request, cancel} = getProjectList(params);

    this.cancelSearch = cancel;

    return request
      .then((res) => {
        this.setState({
          projectList: res.data.map(this.createProjectItems),
          spin: false
        });
      })
      .catch((err) => {
        this.setState({spin: false});
        twin.setSnackbar(err.message);
      });
  };

  createProjectItems = (item: any) => new ProjectItem(item);

  toggleAssignOnly = () => this.setState({assignOnly: !this.state.assignOnly});

  onSearchChange = (search: string) => {
    this.setState({search});
  };

  onSelectInputChange = (prop: TSelectInputs, values: string[]): void => {
    // @ts-ignore -- doesn't like partial state update
    this.setState({
      [prop]: values
    });
  };

  onClearSearch = () => this.setState({search: ''});

  selectProjectType = (projectTypeId: number, item: ProjectItem, cb?: () => void) => {
    item.projectTypeId = projectTypeId;
    this.replaceItemInState(item);
    if (typeof cb === 'function') cb();
  };

  selectStatus = (statusId: number, item: ProjectItem, cb?: () => void) => {
    item.statusId = statusId;
    this.replaceItemInState(item);
    if (typeof cb === 'function') cb();
  };

  onSave = async (item: ProjectItem, cb?: () => void) => {
    try {
      item.isSaving = true;
      this.replaceItemInState(item);
      if (typeof cb === 'function') cb();
      if (item.hasMap) {
        await updateProjectMap(item.projMapId as number, {
          projectTypeId: item.projectTypeId,
          statusId: item.statusId
        });
      } else {
        await createProjectMap({
          statusId: item.statusId,
          projectTypeId: item.projectTypeId,
          projectId: item.projectId,
          projectNo: item.projectNo
        });
      }
      item.save();
      this.replaceItemInState(item);
      if (typeof cb === 'function') cb();
    } catch (err) {
      item.isError = true;
      this.replaceItemInState(item);
      if (typeof cb === 'function') cb();
      twin.setSnackbar(err.message);
    }
  };

  onCancel = (item: ProjectItem, cb?: () => void) => {
    item.cancel();
    this.replaceItemInState(item);
    if (typeof cb === 'function') cb();
  };

  replaceItemInState = (item: ProjectItem) => {
    // find item in list
    const {projectList} = this.state;
    const itemIdx = projectList.findIndex(value => value.projectId === item.projectId);
    if (itemIdx > -1) {
      // replace item in list
      const newProjectList = [
        ...projectList.slice(0, itemIdx),
        item,
        ...projectList.slice(itemIdx + 1)
      ];
      this.setState({projectList: newProjectList});
    } else {
      console.log('not found');
    }
  };

  render() {
    const {
      projectList, projectTypes, statuses, spin, assignOnly, search, projectType, status
    } = this.state;
    return (
      <>
        <Spinner spin={spin}/>
        {
          // cannot load table if no statuses and project types loaded
          (projectTypes.length && statuses.length) ? (
            <ProjectTable
              data={projectList}
              projectTypes={projectTypes}
              projectType={projectType}
              statuses={statuses}
              status={status}
              toggleAssignOnly={this.toggleAssignOnly}
              assignOnly={assignOnly}
              onSelectProjectType={this.selectProjectType}
              onSelectStatusType={this.selectStatus}
              onSave={this.onSave}
              onCancel={this.onCancel}
              doSearch={this.getData}
              onSearchChange={this.onSearchChange}
              onSelectInput={this.onSelectInputChange}
              searchValue={search}
              clearSearch={this.onClearSearch}
            />
          ) : <span/>
        }
      </>
    );
  }
}

export default ProjectTypes;

interface IProjectTypesProps {
  title: string;
}

interface IProjectTypesState {
  projectList: ProjectItem[];
  projectTypes: IProjectType[];
  statuses: IStatus[];
  search: string;
  projectType: string[],
  status: string[];
  assignOnly: boolean;
  spin: boolean;
}

export type TSelectInputs = 'projectType' | 'status';