import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import {Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow} from '@devexpress/dx-react-grid-material-ui';
import {
  Column,
  CustomPaging,
  Filter,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  SortingState
} from '@devexpress/dx-react-grid';
import Refresh from '@material-ui/icons/Refresh';
import Spinner from 'react-spinkit';
import ActionsMenu from './ActionsMenu';
import accounting from 'accounting';
import {IContract} from '../../utils/actions';
import {createStyles, TableCell, Theme, WithStyles, withStyles} from '@material-ui/core';
import {blue} from '@material-ui/core/colors';
import {TStatus} from '../../utils/constants';
import {IPager} from '../../utils/ajax';
accounting.settings.currency.precision = 0;
const twin: any = window;
const goToProject = twin.unifierExtensions.goToProject,
  openPO = twin.unifierExtensions.openPO;
const initialState: ITableState = {
  page: 0
};

class TableComponent extends Component<ITableProps, ITableState> {
  private getData: (pager: IPager) => void;

  constructor(props: ITableProps) {
    super(props);
    this.state = initialState;
    this.getData = this.props.getData();
  }

  componentDidMount() {
    this.getData({skip: 0, top: 10});
  }

  componentDidUpdate(prevProps: ITableProps, prevState: ITableState) {
    // invoked immediately after DOM updates, not for initial 'render'
    // when tab changes
    if (prevProps.title !== this.props.title) {
      this.getData({skip: 0, top: 10});
    } else if (prevState.page !== this.state.page) {
      // when page changes
      this.getData({top: 10, skip: this.state.page * 10});
    }
  }

  onPageChange = (page: number) => this.setState({page});

  render() {
    let columnExtensions: Table.ColumnExtension[] = [
        {columnName: 'projectTitle', width: 350},
        {columnName: 'contractNumber', width: 200},
        {columnName: 'title', width: 400},
        {columnName: 'vendor', width: 200},
        {columnName: 'poReviewComments', width: 250},
        {columnName: 'constructionAmount', width: 150, align: 'right'} as Table.ColumnExtension
      ],
      columns: Column[] = [
        {
          name: 'projectTitle', title: 'Project Title',
          getCellValue: ({projectId, projectNumber, projectTitle, campus}: IContract) => {
            return (
              <Tooltip
                placement="top"
                title={`(Click to Open) ${campus}: ${projectNumber}`}
              >
                <span
                  style={{cursor: 'pointer', textDecoration: 'underline'}}
                  onClick={() => {
                    goToProject(projectId);
                  }}
                >
                {projectTitle}
                </span>
              </Tooltip>
            );
          }
        }, {
          name: 'contractNumber', title: 'Contract No.',
          getCellValue: ({projectId, contractNumber, poId}: IContract) => {
            return (
              <Tooltip placement="top" title="Click to Open">
                <span
                  style={{cursor: 'pointer', textDecoration: 'underline'}}
                  onClick={() => {
                    openPO(projectId, poId);
                  }}
                >
                  {contractNumber}
                </span>
              </Tooltip>
            );
          }
        },
        {name: 'title', title: 'Title', getCellValue: ({title}: IContract) => (<span><span>{title}</span></span>)},
        {name: 'vendor', title: 'Vendor', getCellValue: ({vendor}: IContract) => (<span><span>{vendor}</span></span>)},
        {
          name: 'poReviewComments',
          title: 'Review Comments',
          getCellValue: ({poReviewComments}: IContract) => (<span><span>{poReviewComments}</span></span>)
        },
        {
          name: 'constructionAmount',
          title: 'Construction $',
          getCellValue: ({constructionAmount}: IContract) => {
            return <span><span>{accounting.formatMoney(constructionAmount)}</span></span>;
          },
        }
      ];
    const {spin, data, getData, title, hideComments, classes, ...rest} = this.props;

    if (hideComments) {
      columns = [...columns.slice(0, 4), ...columns.slice(5)];
      columnExtensions = [...columnExtensions.slice(0, 4), ...columnExtensions.slice(5)];
    }

    if (rest.setActive || rest.setComplete || rest.setExclude) {
      columns.unshift({
        name: 'actions',
        title: 'Actions',
        getCellValue({poId, poReviewId, poReviewComments, poReviewStatus: status}: any) {
          return (
            <ActionsMenu
              poId={poId}
              poReviewId={poReviewId}
              comments={poReviewComments}
              status={status}
              {...rest}
            />
          );
        }
      });
      columnExtensions.unshift({columnName: 'actions', align: 'left', width: 50});
    }

    return (
      <div className={classes.table}>
        <Typography
          className={classes.icon}
          variant="h4"
        >
          {title}
          <span title="Refresh Data">
            <Refresh
              style={{cursor: 'pointer', marginBottom: '0'}}
              className={`${classes.icon} ${classes.iconBlue}`}
              onClick={()=> this.getData({top: 10, skip: this.state.page * 10})}
            />
          </span>
        </Typography>
        {
          spin ? (
            <span className={classes.spinner}><Spinner name="cube-grid"/></span>
          ) : (
            <Grid
              rows={data}
              columns={columns}
            >
              <FilteringState columnExtensions={filterColumnExtensions}/>
              <IntegratedFiltering columnExtensions={defaultFilters}/>
              <SortingState columnSortingEnabled={true}/>
              <IntegratedSorting columnExtensions={defaultSort}/>
              <PagingState
                currentPage={this.state.page}
                onCurrentPageChange={this.onPageChange}
                pageSize={10}
              />
              <CustomPaging
                totalCount={this.props.totalCount}
              />
              <Table cellComponent={tableCellTemplate} columnExtensions={columnExtensions}/>
              <TableHeaderRow showSortingControls={true}/>
              <TableFilterRow/>
              <PagingPanel/>
            </Grid>)
        }
      </div>
    );
  }
}

const styles = (theme: Theme) => createStyles({
  table: {
    paddingTop: '1rem'
  },
  icon: {
    margin: theme.spacing(1)
  },
  iconBlue: {
    fill: blue[500],
    '&:hover': {
      fill: blue[200]
    }
  },
  spinner: {
    position: 'relative',
    top: '50%',
    left: '50%'
  }
});

export default withStyles(styles)(TableComponent);

const tableCellTemplate = ({value}: Table.DataCellProps) => {
  return <TableCell style={{whiteSpace: 'initial'}}>{value}</TableCell>;
};
const filterColumnExtensions: FilteringState.ColumnExtension[] = [
  {columnName: 'actions', filteringEnabled: false}
];

const stringContainsFilter = (cell: any, filter: Filter) => {
  if (!(cell && cell.props.children && cell.props.children.props.children && cell.props.children.props.children.toLowerCase)) {
    return false;
  }
  // @ts-ignore
  return (new RegExp(filter.value.toLowerCase(), 'i')).test(cell.props.children.props.children.toLowerCase());
};

const moneyContainsFilter = (cell: any, filter: Filter) => {
  // @ts-ignore
  return String(accounting.unformat(cell.props.children.props.children)).includes(filter.value);
};
const stringFilterCols = ['projectTitle', 'contractNumber', 'title', 'vendor', 'poReviewComments'];
const moneyFilterCols = ['constructionAmount', 'nonConstructionAmount'];

function createFilter(predicate: any, columnName: string): IntegratedFiltering.ColumnExtension {
  return {columnName, predicate};
}

// @ts-ignore
const defaultFilters: IntegratedFiltering.ColumnExtension[] = [
  ...stringFilterCols.map(createFilter.bind(null, stringContainsFilter)),
  ...moneyFilterCols.map(createFilter.bind(null, moneyContainsFilter))
];

const stringSort = (a: any, b: any) => {
  if (!(a && a.props.children && a.props.children.props.children && a.props.children.props.children.localeCompare)) {
    return -1;
  }
  return a.props.children.props.children.localeCompare(b.props.children.props.children);
};

const moneySort = (_a: any, _b: any) => {
  const a = accounting.unformat(_a.props.children.props.children);
  const b = accounting.unformat(_b.props.children.props.children);
  return a > b ? 1 : -1;
};

function createSort(compare: any, columnName: string) {
  return {columnName, compare};
}

const stringSortCols = ['projectTitle', 'contractNumber', 'vendor', 'title', 'poReviewComments'];
const moneySortCols = ['constructionAmount', 'nonConstructionAmount'];
// @ts-ignore compiler thinks that an empty object will be present.
const defaultSort: IntegratedSorting.ColumnExtension[] = [
  ...stringSortCols.map(createSort.bind(null, stringSort)),
  ...moneySortCols.map(createSort.bind(null, moneySort))
];

interface ITableProps extends WithStyles<typeof styles> {
  data: IContract[];
  totalCount: number;
  title: string;
  spin: boolean;
  hideComments?: boolean
  getData(): (pager: IPager) => void;
  updateComment(poId: number, poReviewId: number, comments: string, status: TStatus): void;
  setActive?(poId: number, poReviewId: number, comments: string, fromStatus: TStatus): void;
  setComplete?(poId: number, poReviewId: number, comments: string, fromStatus: TStatus): void;
  setExclude?(poId: number, poReviewId: number, comments: string, fromStatus: TStatus): void;
}

interface ITableState {
  page: number;
}