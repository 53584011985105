import {Omit} from '@material-ui/core';
import ajax, {getCancelConfig, getResCount, ICancellable, ICDataParams, ICountRes} from '../../utils/ajax';


const PROJECT_LIST = 'unifierProjectList';
const PROJECT_MAP = 'unifierProjectMap';
export const getProjectList = (params: ICDataParams): ICancellable<Promise<ICountRes<IProjectListItem[]>>> => {
  const {cancel, cancelToken} = getCancelConfig();
  return {
    request: ajax.get(PROJECT_LIST, {params, cancelToken}).then((res) => getResCount<IProjectListItem[]>(res)),
    cancel
  };
};

export interface IProjectListItem {
  campus: string;
  projectTitle: string;
  projectNo: string;
  projectId: number;
  projMapId?: number | null;
  unifierProjectType: string;
  projectType?: string | null;
  projectTypeId?: number | null;
  status?: string;
  statusId?: number;
  hasMap: 1 | 0; // hasMap ? update unifierProjects : create unifierProjects
  hasType: 1 | 0;
  hasStatus: 1 | 0;
  budgetAmount: number;
  costToDate: number | string;
  constructionAmount: number;
  statusDescription?: string | null;
}

export const createProjectMap = (data: ICreateProjectMap) => {
  return ajax.post(PROJECT_MAP, data);
};
export const updateProjectMap = (id: number, data: IUpdateProjectMap): Promise<any> => {
  return ajax.put(`${PROJECT_MAP}(${id})`, data);
};

export interface IUpdateProjectMap extends Omit<ICreateProjectMap, 'id' | 'projectId' | 'projectNo'> {}

export interface ICreateProjectMap {
  projectNo: string;
  projectId: number;
  statusId?: number;
  projectTypeId?: number;
}