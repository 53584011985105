import React, {PureComponent} from 'react';
import {
  Button,
  createStyles,
  FormControlLabel,
  InputAdornment,
  Paper,
  Switch,
  TextField,
  Typography,
  WithStyles,
  withStyles
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {IProjectListItem} from './projectTypeApi';
import {AgGridReact as Grid} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import {ColDef, GridApi, ValueGetterParams} from 'ag-grid-community';
import accounting from 'accounting';
import SelectInput from '../SelectInput';
import {TSelectInputs} from './ProjectTypes.container';
import {
  GetColDefParams,
  getColumnDefinitions, ActionButtonsComponent,
  ProjectTypeSelectComponent,
  StatusSelectComponent
} from './columnDefinitions';

accounting.settings.currency.precision = 0;

const initialState: IProjectTableState = {};

class ProjectTable extends PureComponent<IProjectTableProps, IProjectTableState> {
  private columns: ColDef[];
  private gridApi: GridApi | undefined;
  constructor(props: IProjectTableProps) {
    super(props);
    this.state = initialState;
    this.columns = getColumnDefinitions({
      statuses: this.props.statuses,
      projectTypes: this.props.projectTypes,
      onSelectProjectType: this.props.onSelectProjectType,
      onSelectStatusType: this.props.onSelectStatusType,
      onSave: this.props.onSave,
      onCancel: this.props.onCancel
    });
  }

  onGridReady = (params: any) => {
    this.gridApi = params.api;
    this.gridApi?.sizeColumnsToFit();
  };

  onSearchInput = (e: any) => {
    this.props.onSearchChange(e.target.value);
  };

  onSelectStatusFilter = (statusFilter: string[]) => this.props.onSelectInput('status', statusFilter);

  onSelectProjectTypeFilter = (projectTypeFilter: string[]) => this.props.onSelectInput('projectType', projectTypeFilter);

  onSearch = (e: any) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    this.props.doSearch();
  };

  getRowStyle = (params: ValueGetterParams) => {
    // if params.data is
    if(params.data.isError) return {backgroundColor: 'orangered', color: 'white', fontWeight: 'bolder'};
    if(params.data.isSaving) return {backgroundColor: 'lightgreen', fontWeight: 'bolder'};
    if(params.data.isTouched) return {backgroundColor: 'lightyellow', fontWeight: 'bolder'};
    return {};
  };

  render() {
    const {classes, searchValue, clearSearch, statuses, status, projectTypes, projectType} = this.props;
    return (
      <Paper className={classes.paperSpace}>
        <Typography variant="h4">Project Type Assignments</Typography>
        <Typography>Assign a Project Type and Status to Unifier Projects.</Typography>
        <Typography>Project Type and Statuses are created in the Admin Panel above.</Typography>
        <div className={classes.paperSpace}>
          <form onSubmit={this.onSearch}>
            <FormControlLabel
              label="Only Show Projects To Assign Type"
              control={
                <Switch
                  checked={this.props.assignOnly}
                  onChange={this.props.toggleAssignOnly}
                />}
            />
            <TextField
              id="project-search"
              label="Project Title / Project No."
              value={searchValue}
              onChange={this.onSearchInput}
              className={classes.textInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className={classes.finger}>
                    <ClearIcon onClick={clearSearch}/>
                  </InputAdornment>
                )
              }}
            />
            <SelectInput
              onChange={this.onSelectStatusFilter}
              multiple={true}
              selections={status}
              name="select-status"
              label="Status"
              options={statuses.map((status) => ({name: status.name, value: status.name}))}
            />
            <SelectInput
              onChange={this.onSelectProjectTypeFilter}
              multiple={true}
              selections={projectType}
              name="select-project-type"
              label="Project Type"
              options={projectTypes.map((projectType) => ({
                name: projectType.projectType,
                value: projectType.projectType
              }))}
            />
            <Button type="submit" variant="contained">Search</Button>
          </form>
          <div
            className="ag-theme-material"
            style={{
              paddingTop: '0.5rem',
              height: '70vh',
              left: '0.5rem',
              right: '0.5rem'
            }}
          >
            <Grid
              pagination={true}
              paginationAutoPageSize={true}
              colResizeDefault={"shift"}
              columnDefs={this.columns}
              rowData={this.props.data}
              getRowStyle={this.getRowStyle}
              onGridReady={this.onGridReady}
              frameworkComponents={{
                projectTypeSelectComponent: ProjectTypeSelectComponent,
                statusSelectComponent: StatusSelectComponent,
                actionButtonsComponent: ActionButtonsComponent
              }}
            />
          </div>
        </div>
      </Paper>
    );
  }
}

const styles = createStyles({
  paperSpace: {
    padding: '1rem',
    margin: '1rem'
  },
  spinner: {
    position: 'relative',
    top: '50%',
    left: '50%'
  },
  textInput: {
    minWidth: '10rem',
    marginLeft: '1rem',
    marginRight: '1rem'
  },
  finger: {
    cursor: 'pointer'
  }
});

export default withStyles(styles)(ProjectTable);

interface IProjectTableProps extends WithStyles<typeof styles>, GetColDefParams {
  data: IProjectListItem[];
  projectType: string[];
  status: string[];
  assignOnly: boolean;
  searchValue: string;
  clearSearch(): void;
  toggleAssignOnly(): void;
  onSearchChange(search: string): void;
  doSearch(): void;
  onSelectInput(prop: TSelectInputs, values: string[]): void;
}

interface IProjectTableState {
}
